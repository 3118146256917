import React, { useCallback, useEffect, useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import { Box, Paper } from '@material-ui/core';
import { v2, v2Base } from '../../../axios';
import Informations from './Informations';

import DecideTreatment from './decideTreatment/DecideTreatment';
import Treatment from './treatment/Treatment';
import useLabReport from '../../labReport/useLabReport';
import PatientTabs from './PatientTabs';

import TreatmentPlan from './TreatmentPlan';
import Comments from './comments/Comments';
import Questions from './Questions';
import Upload from '../../Upload';
import PhotosUpload from '../../PhotosUpload';
import { Tags } from './Tags/Tags';

import PreviewLabReport from '../../labReport/PreviewLabReport';
import DownloadLabReport from '../../labReport/DownloadLabReport';
import { PATIENT_TABS } from '../../../constants/patients';

const styles = (theme) => ({
  patientWrapper: {
    padding: theme.spacing(2)
  }
});

const View = ({ match, classes, snackbar }) => {
  const [patient, setPatient] = useState(null);
  const [tags, setTags] = useState([]);
  const [activeTab, setActiveTab] = useState(PATIENT_TABS.TREATMENT_PLAN);
  const { labReport } = useLabReport(patient && patient.id);

  const fetchPatient = useCallback(async () => {
    try {
      const patientDataPromises = [
        v2.get(`/patients/${match.params.id}`),
        v2Base.get(`/patients/${match.params.id}/tags`)
      ];
      const [{ data: fetchedPatient }, { data: fetchedTags }] =
        await Promise.all(patientDataPromises);

      if (fetchedPatient.treatment_started_at) {
        setActiveTab(PATIENT_TABS.TREATMENT);
      }

      setPatient(fetchedPatient);
      setTags(fetchedTags);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [match.params.id]);

  useEffect(() => {
    fetchPatient();
  }, [fetchPatient]);

  if (!patient) {
    return null;
  }

  return (
    <>
      <Card className={classes.patientWrapper}>
        <Informations patient={patient} tags={tags} />
      </Card>

      <PatientTabs
        patient={patient}
        activeTab={activeTab}
        onChange={(e, tab) => {
          setActiveTab(tab);
        }}
        labReport={labReport}
      />

      {activeTab === PATIENT_TABS.TREATMENT && (
        <Treatment
          fetchPatient={fetchPatient}
          patient={patient}
          snackbar={snackbar}
        />
      )}

      {activeTab === PATIENT_TABS.TREATMENT_PLAN &&
        patient.treatment_plan_uploaded && (
          <>
            <DecideTreatment
              fetchPatient={fetchPatient}
              patient={patient}
              snackbar={snackbar}
            />
            <TreatmentPlan patient={patient} />
          </>
        )}

      {activeTab === PATIENT_TABS.LAB_REPORT && (
        <Box mt={4}>
          <Box mt={4}>
            <DownloadLabReport patientId={patient.id} />
          </Box>
          <PreviewLabReport patientId={patient.id} />
        </Box>
      )}

      {activeTab === PATIENT_TABS.PHOTOS && (
        <>
          <PhotosUpload patient={patient} serie="check_up" readOnly />
          <br />
          <Upload
            description="X-ray"
            receiverId={patient.id}
            receiverType="patient"
            type="treatment_xray"
            fileName={`xray-${patient.reference}`}
            autoUpload
            readOnly
          />
        </>
      )}
      {activeTab === PATIENT_TABS.MEDICAL_INFORMATIONS && (
        <Paper className={classes.root}>
          <Questions patient={patient} />
        </Paper>
      )}

      {activeTab === PATIENT_TABS.COMMENTS && <Comments patient={patient} />}
      {activeTab === PATIENT_TABS.SETTINGS && (
        <Tags patientTags={tags} onSave={setTags} patientId={match.params.id} />
      )}
    </>
  );
};

export default withStyles(styles)(View);
