import React, { Component } from 'react';

import Authenticated from './Authenticated';
import LoadingPage from './LoadingPage';
import RedirectToAuth from './RedirectToAuth';

import { api, v2 } from '../axios';
import initializeIntercom from '../intercom';

class Layout extends Component {
  state = {
    fetchedUser: false,
    user: null
  };

  async componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    try {
      const { data: user } = await v2.get('/auth/user');

      this.setState({ user }, () => {
        initializeIntercom(user);
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      this.setState({ fetchedUser: true });
    }
  };

  logout = async () => {
    try {
      await api.get('/auth/logout');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      this.setState({ user: null });
    }
  };

  render() {
    const { fetchedUser, user } = this.state;

    if (!fetchedUser) {
      return <LoadingPage />;
    }

    if (!user) {
      return <RedirectToAuth />;
    }

    return (
      <Authenticated
        fetchUser={this.fetchUser}
        logout={this.logout}
        user={user}
      />
    );
  }
}

export default Layout;
