import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  format as formatDate,
  formatDistance,
  formatRelative,
  isDate
} from 'date-fns';
import { enUS, fr as dateFr, es as dateEs } from 'date-fns/locale';
import fr from './lang/fr';
import en from './lang/en';
import es from './lang/es';

const locales = { en: enUS, fr: dateFr, es: dateEs };

const resources = {
  fr,
  en,
  es
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',

  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (isDate(value)) {
        const locale = locales[lng];

        if (format === 'relative') {
          return formatRelative(value, new Date(), { locale });
        }
        if (format === 'ago') {
          return formatDistance(value, new Date(), {
            locale,
            addSuffix: true
          });
        }

        return formatDate(value, format, { locale });
      }

      return value;
    }
  }
});

export default i18n;
