import React, { Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  ListItemAvatar,
  Paper,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListSubheader,
  Box
} from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';

import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import Loadable from '../Loadable';

import { v2 } from '../../axios';

const styles = () => ({
  root: {
    height: 280,
    overflowY: 'auto'
  }
});

const TreatmentPlans = ({ classes }) => {
  const [patients, setPatients] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation(['dashboard', 'common']);

  useEffect(() => {
    const fetchPatients = async () => {
      setIsFetching(true);
      try {
        const { data: fetchedPatients } = await v2.get('/patients', {
          params: { status: 'treatment-plan' }
        });

        setPatients(fetchedPatients);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchPatients();
  }, []);

  return (
    <Grid item xs={12} sm={12} md={6} lg={4}>
      <Paper className={classes.root}>
        <List
          subheader={
            <ListSubheader component="div" color="primary">
              {t('dashboard:dashboard.treatmentPlans.title')}
            </ListSubheader>
          }
        >
          <Loadable loading={isFetching}>
            {patients.length ? (
              <>
                {patients.slice(0, 3).map((p) => (
                  <ListItem
                    button
                    component={Link}
                    to={`/patient/${p.id}`}
                    key={p.id}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={p.user.fullname}
                      secondary={t('common:common.date.ago', {
                        date: parseISO(p.treatment_plan_uploaded_at)
                      })}
                    />
                  </ListItem>
                ))}
                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    align="center"
                    component={Link}
                    to="/patients/treatment-plan"
                  >
                    {t('dashboard:dashboard.treatmentPlans.seeAll')}
                  </Typography>
                </Box>
              </>
            ) : (
              <ListItem>
                <ListItemText
                  primary={t('dashboard:dashboard.treatmentPlans.empty')}
                />
              </ListItem>
            )}
          </Loadable>
        </List>
      </Paper>
    </Grid>
  );
};

export default withStyles(styles)(TreatmentPlans);
