import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../Dialog';
import { v2 } from '../../../../axios';

const DecideButtons = ({ patient, fetchPatient, snackbar }) => {
  const [decision, setDecision] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('patients');

  const handleDecision = async () => {
    const action = decision ? 'accept' : 'refuse';

    await v2.post(`treatment-plans/${patient.treatment_plan.id}/${action}`);

    await fetchPatient();

    snackbar.open(
      decision
        ? t('patients:patients.decideTreatment.decision.success.accept')
        : t('patients:patients.decideTreatment.decision.success.refuse'),
      'success'
    );

    setOpen(false);
  };

  const handleAccept = () => {
    setOpen(true);
    setDecision(true);
  };

  const handleRefuse = () => {
    setOpen(true);
    setDecision(false);
  };

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Button
          size="small"
          fullWidth
          variant="contained"
          component="span"
          onClick={handleRefuse}
        >
          {t('patients:patients.decideTreatment.decision.refuse')}
        </Button>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          size="small"
          fullWidth
          variant="contained"
          component="span"
          color="secondary"
          onClick={handleAccept}
        >
          {t('patients:patients.decideTreatment.decision.accept')}
        </Button>
        <Dialog
          open={open}
          title={
            decision
              ? t(
                  'patients:patients.decideTreatment.decision.dialog.accept.title'
                )
              : t(
                  'patients:patients.decideTreatment.decision.dialog.refuse.title'
                )
          }
          description={
            decision
              ? t(
                  'patients:patients.decideTreatment.decision.dialog.accept.description'
                )
              : t(
                  'patients:patients.decideTreatment.decision.dialog.refuse.description'
                )
          }
          onCancel={() => setOpen(false)}
          onAccept={handleDecision}
          cancelText={t(
            'patients:patients.decideTreatment.decision.dialog.cancel'
          )}
          confirmText={t(
            'patients:patients.decideTreatment.decision.dialog.confirm'
          )}
        />
      </Grid>
    </>
  );
};

export default DecideButtons;
