import FileSaver from 'file-saver';
import { v2 } from '../axios';

export const download = async ({ endpoint, fileName }) => {
  const { data: content } = await v2.get(endpoint, {
    responseType: 'arraybuffer'
  });

  const informationsDocument = new File([content], fileName, {
    type: `application/pdf`
  });

  return FileSaver.saveAs(informationsDocument);
};
