export const TYPE_LOCAL = 'local';
export const TYPE_MONITORING = 'monitoring';
export const TYPE_PRESCRIPTOR = 'prescriptor';

export const isMonitoringPractician = (patient) => {
  const { type } = patient.pivot;

  return type === TYPE_MONITORING;
};

export const isPrescriptorPractician = (patient) => {
  const { type } = patient.pivot;

  return type === TYPE_PRESCRIPTOR;
};
