import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Form, Field } from 'react-final-form';
import Typography from '@material-ui/core/Typography';

import { format, startOfMonth, subMonths } from 'date-fns';

import { withStyles } from '@material-ui/core/styles';
import { v2 } from '../../axios';
import Select from '../final-form/Select';
import Loadable from '../Loadable';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
});

const Report = ({ classes }) => {
  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async (values) => {
    setIsFetching(true);

    const { data: content } = await v2.get(`/financial-report`, {
      params: {
        date: values.date
      },
      responseType: 'arraybuffer'
    });

    const blob = new Blob([content], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    window.open(url);

    window.URL.revokeObjectURL(url);

    setIsFetching(false);
  };

  const month = startOfMonth(new Date());

  const dates = [...Array(6).keys()].map((index) => {
    const date = subMonths(month, index);

    return {
      display: format(date, 'MMMM yyyy'),
      value: format(date, 'yyyy-MM')
    };
  });

  return (
    <Paper className={classes.root}>
      <Typography variant="h5">Récaputilatif mensuel</Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          date: dates[0].value
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="date"
              render={(props) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <Select {...props} formControlProps={{ fullWidth: true }} />
              )}
            >
              {dates.map((date, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={index} value={date.value}>
                  {date.display}
                </MenuItem>
              ))}
            </Field>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isFetching}
            >
              <Loadable loading={isFetching} size={20} noMessage>
                Génerer
              </Loadable>
            </Button>
          </form>
        )}
      />
    </Paper>
  );
};

export default withStyles(styles)(Report);
