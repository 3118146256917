import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import NoDecision from './NoDecision';
import Decision from './Decision';

const DecideTreatment = ({ patient, fetchPatient, snackbar }) => {
  const { t } = useTranslation('patients');

  const { decision_practician_at } = patient;

  return (
    <Box mt={2}>
      <Paper>
        <Box m={2}>
          <Grid container justifyContent="center" spacing={3}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {patient.treatment_plan_uploaded ? (
              decision_practician_at ? (
                <Box p={2}>
                  <Decision patient={patient} />
                </Box>
              ) : (
                <NoDecision
                  patient={patient}
                  fetchPatient={fetchPatient}
                  snackbar={snackbar}
                />
              )
            ) : (
              <Grid item xs={12}>
                <Typography align="center">
                  {t('patients:patients.decideTreatment.notYetAvailable')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default DecideTreatment;
