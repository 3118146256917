import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { isPrescriptorPractician } from '../../../../utils/patients.utils';

const Decision = ({ patient }) => {
  const { t } = useTranslation(['patients', 'common']);
  const { decision_practician, decision_practician_at } = patient;

  if (!isPrescriptorPractician(patient)) {
    return (
      <Typography>
        {decision_practician
          ? t(
              'patients:patients.decideTreatment.decision.localPractician.accepted'
            )
          : t(
              'patients:patients.decideTreatment.decision.localPractician.refused'
            )}
      </Typography>
    );
  }

  return (
    <Typography>
      {decision_practician
        ? t('patients:patients.decideTreatment.decision.accepted')
        : t('patients:patients.decideTreatment.decision.refused')}
      {t('common:common.date.detailed', {
        date: parseISO(decision_practician_at)
      })}
    </Typography>
  );
};

export default Decision;
